<script>

import appConfig from "@/app.config";
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {

  },
  data() {
    return {
      marketing_campaign_areas: [],
      marketing_campaign_channels: [],
      data: {},
      selectedEventLevel: {},
      MarketingCampaigns: [],
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchMarketingCampaigns: null,
      MarketingCampaignsSearchMode: false,
      searchModel: "",
      campaing: {},
      channel: {},
      event: {},
      channel_levels: [],
    };
  },
  methods: {
    addMarketingCampaigns() {
      this.data.marketing_campaign_channel_event_id =
        this.$route.params?.eventId;
      console.log({ data: this.data });
      this.http
        .post("marketing-campaign-channel-event-levels", this.data, null, true)
        .then((res) => {
          if (res.status) {
            this.popup.alert({ title: '', msg: 'popups.success' })

            this.data = {};
            this.get(this.page);

            document.querySelector('#addModal .close-modal-btn').click()

          }
        });
    },
    editMarketingCampaigns() {
      console.log({ selectedEventLevel: this.selectedEventLevel });
      this.http
        .put(
          "marketing-campaign-channel-event-levels",
          this.selectedEventLevel?.id,
          this.selectedEventLevel
        )
        .then((res) => {
          if (res.status) {

            this.data = {};
            this.get(this.page);
            document.querySelector("#editModal .close-modal-btn").click();
            this.popup.alert({ title: '', msg: 'popups.success' })

          }
        });
    },
    search() {
      this.MarketingCampaignsSearchMode = true;
      this.tot_pages = 0;
      this.http
        .post("marketing-campaign-channel-event-levels/by-event-id/search", {
          search: this.searchModel,
          limit: this.limit,
          page: this.page,
          col: "name",
          event_id: this.$route.params?.eventId,
        }, null, true)
        .then((res) => {
          this.MarketingCampaigns = res.data;
          console.log("######## files", this.MarketingCampaigns);
        });
    },
    cancelappsearchMode() {
      this.searchModel = "";
      this.MarketingCampaignsSearchMode = false;
      this.get(this.page);
    },
    deleteMarketingCampaigns(app) {
      var data = {
        title: "popups.deletefile",
        msg: "popups.delmessage",
      };
      this.popup.confirm(data).then((resp) => {
        if (resp) {
          console.log("###");
          this.http
            .delete("marketing-campaign-channel-event-levels", app.id)
            .then(() => {
              console.log("############################3");
              this.get(this.page);
            });
        }
      });
    },
    getCampaign() {
      this.http
        .get("marketing-campaigns/" + this.$route.params?.id, null, null, true)
        .then((res) => {
          this.campaing = res.data;
          console.log({ campaing: this.campaing });
        });
    },
    getChannel() {
      this.http
        .get("marketing-campaign-channels/" + this.$route.params?.channelId, null, null, true)
        .then((res) => {
          this.channel = res.data?.marketing_channel;
          this.channel_levels =
            res.data?.marketing_channel?.marketing_channel_levels;
          console.log({
            channel: this.channel,
            channel_levels: this.channel_levels,
          });
        });
    },
    getEvent() {
      this.http
        .get(
          "marketing-campaign-channel-events/" + this.$route.params?.eventId, null, null, true
        )
        .then((res) => {
          this.event = res.data;
          console.log({
            event: this.event,
          });
        });
    },
    get(page) {
      console.log(page);
      this.http
        .post(
          "marketing-campaign-channel-event-levels/by-event-id/pagination",
          {
            limit: this.limit,
            page: page,
            marketing_campaign_channel_event_id: this.$route.params?.eventId,
          }, null, true
        )
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.MarketingCampaigns = res.data;
          // res.data.forEach((item) => {
          //   this.marketing_campaign_areas.push(item.marketing_campaign_areas);
          // });

          // res.data.forEach((item) => {
          //   this.marketing_campaign_channels.push(
          //     item.marketing_campaign_channels
          //   );
          // });
        });
    },
    setSelectedEvent(app) {
      if (app?.start_time) app.start_time = app.start_time.split("T")[0];
      if (app?.finish_time) app.finish_time = app.finish_time.split("T")[0];
      console.log({ selectedEventLevel: app });
      this.selectedEventLevel = JSON.parse(JSON.stringify(app));
    },
  },
  created() {
    this.getChannel();
    this.getCampaign();
    this.getEvent();
    this.get(1);
  },
};
</script>

<template>
  <div class="d-flex align-items-center justify-content-between mb-4">
    <div class="d-flex align-items-center">
      <router-link :to="`/dashboard/marketing_campaign_management`">
        <h4 class="m-0">
          {{
            $t(
              "menu.menuitems.marketing.subItems.marketing_campaign_management"
            )
          }}
        </h4>
      </router-link>
      <!-- /marketing_campaign_channels_events/:eventId -->
      <div class="mx-2">\</div>
      <router-link :to="`/dashboard/marketing_campaign_management/${this.$route.params?.id}`">
        <div>{{ campaing?.name }}</div>
      </router-link>
      <div class="mx-2">\</div>
      <router-link
        :to="`/dashboard/marketing_campaign_management/${this.$route.params?.id}/marketing_campaign_channels/${this.$route.params?.channelId}`">
        <div>{{ `${channel?.name} ${$t("marketing.events")}` }}</div>
      </router-link>
      <div class="mx-2">\</div>
      <div>{{ `${event?.name}` }}</div>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <div class="search-box chat-search-box w-50">
        <div class="position-relative">
          <input @keyup="search()" type="text" class="form-control fa-lg text-light"
            :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" :placeholder="$t('popups.search')" v-model="searchModel"
            style="background-color: #2a3042 !important" />
          <i class="bx bx-search-alt search-icon text-light"></i>
        </div>
      </div>
    </div>
    <div class="col-6" style="display: flex; justify-content: flex-end">
      <button class="btn-close text-light" v-if="MarketingCampaignsSearchMode" @click="cancelappsearchMode()"></button>
      <button type="button" data-bs-toggle="modal" data-bs-target="#addModal" class="btn btn-light float-end mb-4">
        <span class="bx bxs-plus-circle float-end fa-2x" style="color: #2a3042 !important"></span>
      </button>
    </div>
  </div>

  <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
    <thead>
      <tr class="text-light text-center" style="background-color: #2a3042 !important">
        <th scope="col">{{ $t("marketing.id") }}</th>
        <!-- <th scope="col">{{ $t("marketing.event") }}</th> -->
        <th scope="col">{{ $t("marketing.level") }}</th>
        <th scope="col">{{ $t("marketing.reach") }}</th>
        <th scope="col">{{ $t("marketing.created") }}</th>
        <th scope="col">{{ $t("marketing.updated") }}</th>
        <th scope="col">{{ $t("marketing.operations") }}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(app, i) in MarketingCampaigns" :key="app" class="text-center">
        <td>{{ i + 1 }}</td>
        <!-- <td>{{ app?.level?.event ? app?.level?.event : "--" }}</td> -->
        <td>
          {{
            app?.marketing_channel_level?.name
            ? app?.marketing_channel_level?.name
            : "--"
          }}
        </td>
        <td>{{ app?.reach }}</td>
        <td>{{ app?.created?.split("T")[0] }}</td>
        <td>{{ app?.updated?.split("T")[0] }}</td>

        <td class="d-flex justify-content-end">
          <a class="btn btn-primary mx-1" data-bs-target="#editModal" data-bs-toggle="modal"
            @click="setSelectedEvent(app)" href="javascript: void(0);" role="button">{{ $t("popups.edit") }}</a>
          <a class="btn btn-danger" href="javascript: void(0);" role="button" @click="deleteMarketingCampaigns(app)">{{
            $t("popups.delete") }}</a>
        </td>
      </tr>
    </tbody>
  </table>
  <!-- end  table -->
  <!-- Button trigger modal -->

  <!--Start Add Modal-->
  <div class="modal fade" id="addModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
    :dir="$i18n.locale == 'ar' ? 'rtl' : ''">
    <div class="modal-dialog">
      <div class="modal-content">
        <form @submit.prevent="addMarketingCampaigns()">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ $t("popups.add") }}
            </h5>
            <button type="button" class="btn-close-add" data-bs-dismiss="modal" aria-label="Close" style="margin: 0">
              X
            </button>
          </div>

          <div class="modal-body">
            <label>{{ $t("marketing.event") }}</label>
            <select v-model="data.marketing_channel_level_id" class="mb-2 form-control" required>
              <option v-for="item in channel_levels" :key="item?.id" :value="item.id">
                {{ item?.name }}
              </option>
            </select>
            <label for="reach">{{ $t("marketing.reach") }}</label>
            <input v-model="data.reach" id="reach" required class="form-control mb-2" type="number"
              :placeholder="$t('marketing.reach')" />
          </div>
          <div class="modal-footer" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
            <button type="submit" class="btn btn-primary">
              {{ $t("popups.add") }}
            </button>
            <button data-bs-dismiss="modal" type="button" class="btn close-modal-btn btn-danger">
              {{ $t("popups.cancel") }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!--End Add Modal-->

  <!--Start Edit Modal-->
  <div class="modal fade" id="editModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
    :dir="$i18n.locale == 'ar' ? 'rtl' : ''">
    <div class="modal-dialog">
      <div class="modal-content">
        <form @submit.prevent="editMarketingCampaigns()">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ $t("popups.edit") }}
            </h5>
            <button type="button" class="btn-close-edit" data-bs-dismiss="modal" aria-label="Close" style="margin: 0">
              X
            </button>
          </div>

          <div class="modal-body">
            <!-- <label>{{ $t("marketing.event") }}</label>
              <select
                v-model="selectedEventLevel.marketing_channel_level_id"
                class="mb-2 form-control"
                required
              >
                <option
                  v-for="item in channel_levels"
                  :key="item?.id"
                  :value="item.id"
                >
                  {{ item?.name }}
                </option>
              </select> -->
            <label for="reach">{{ $t("marketing.reach") }}</label>
            <input v-model="selectedEventLevel.reach" id="reach" required class="form-control mb-2" type="number"
              :placeholder="$t('marketing.reach')" />
          </div>
          <div class="modal-footer" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
            <button type="submit" class="btn btn-primary">
              {{ $t("popups.edit") }}
            </button>
            <button data-bs-dismiss="modal" type="button" class="btn close-modal-btn  btn-danger">
              {{ $t("popups.cancel") }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!--End Edit Modal-->

  <div>
    <!--   Apps  Pagination     -->
    <ul class="pagination pagination-rounded justify-content-center mb-2">
      <li class="page-item" :class="{ disabled: page == 1 }">
        <a class="page-link" href="javascript: void(0);" @click="get(page - 1)" aria-label="Previous">
          <i class="mdi mdi-chevron-left"></i>
        </a>
      </li>
      <li class="page-item" :class="{ active: p == page }" v-for="p in tot_pages" :key="p">
        <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
          p
        }}</a>
      </li>

      <li class="page-item" :class="{ disabled: page == tot_pages }">
        <a class="page-link" href="javascript: void(0);" @click="get(page + 1)" aria-label="Next">
          <i class="mdi mdi-chevron-right"></i>
        </a>
      </li>
    </ul>
  </div>
  <!--end-->
</template>

<style scoped>
.btn-close {
  position: relative;
  right: 317px;
  top: 6px;
  font-size: 15px;
}
</style>
